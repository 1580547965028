import { useState, useCallback, useEffect } from "react";
import Cookies from "js-cookie";

export const useProfile = () => {
  const isSsr = typeof window === "undefined";
  const localData = !isSsr ? localStorage.getItem("userData") : null;
  const user = localData ? JSON.parse(localData).user : null;
  const token = Cookies.get("token");
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState<any>(user);

  const getProfile = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.GATSBY_BE_URL}/users/getUserProfile`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const userData = await response.json();
      setUserData(userData.user);
      const newLocalData = { ...JSON.parse(localData), user: userData.user };
      localStorage.setItem("userData", JSON.stringify(newLocalData));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (token) {
      getProfile();
    }
  }, []);

  return { userData, getProfile, token };
};
