import { Col, Row, Typography } from "antd";
import React, { useCallback, useRef } from "react";
import OpportunityCard from "../../OpportunityCard";
import OpportunityCardSkeleton from "../../Skeletons/OpportunityCardSkeleton";
import LoaderIll from "../../../assets/mentroWebApp/loaderIll.svg";
import "./Opportunities.less";

const { Title } = Typography;
const jobsPerRow = 3;
const jobsPerRowMobView = 2;

const Opportunities = ({ jobHookData }) => {
  const [jobOpportunities, loading, hasMoreJobs, setDocId, dataReceived] =
    jobHookData;
  // -----------------------------------------------------------------
  // INFINITE SCROLLING-----------------------------------------------------------------
  // -----------------------------------------------------------------
  const observer = useRef<any>();
  const lastJobOppElement = useCallback(
    (ele) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMoreJobs) {
          if (
            typeof window !== "undefined" &&
            window.innerWidth >= 768 &&
            jobOpportunities.length % jobsPerRow !== 0
          ) {
            return;
          } else if (
            typeof window !== "undefined" &&
            window.innerWidth < 768 &&
            jobOpportunities.length % jobsPerRowMobView !== 0
          ) {
            return;
          }
          setDocId(jobOpportunities[jobOpportunities.length - 1].id);
        }
      });
      if (ele) observer.current.observe(ele);
    },
    [loading, hasMoreJobs]
  );

  console.log("JSX JOBOPP: ", jobOpportunities);

  const showLoader = () => {
    if (typeof window !== "undefined" && window.innerWidth >= 768) {
      return Array.from(Array(jobsPerRow)).map(() => (
        <Col lg={8} xs={12} className="oppSkelCardCol">
          <OpportunityCardSkeleton />
        </Col>
      ));
    } else {
      return Array.from(Array(jobsPerRowMobView)).map(() => (
        <Col xs={12} className="oppSkelCardCol">
          <OpportunityCardSkeleton />
        </Col>
      ));
    }
  };

  console.log("DATA RECEIVED: ", dataReceived);

  return (
    <div className="oppDiv">
      <Title className="oppTitle">Job Opportunites</Title>
      <Row className="oppRow">
        {jobOpportunities.length > 0 ? (
          <>
            {jobOpportunities &&
              jobOpportunities.map((jobOpp, index) => {
                if (index + 1 === jobOpportunities.length) {
                  return (
                    <Col
                      lg={8}
                      xs={12}
                      ref={lastJobOppElement}
                      key={index}
                      className="oppCardCol"
                    >
                      <OpportunityCard jobOpp={jobOpp} />
                    </Col>
                  );
                } else {
                  return (
                    <Col lg={8} xs={12} key={index} className="oppCardCol">
                      <OpportunityCard jobOpp={jobOpp} />
                    </Col>
                  );
                }
              })}
            {loading && hasMoreJobs && showLoader()}
          </>
        ) : (
          <div className="loaderSection">
            {dataReceived && jobOpportunities.length === 0 ? (
              <div className="noJobs">
                <img src={LoaderIll} />
                <Title className="noJobsText">No Jobs Found</Title>
              </div>
            ) : (
              showLoader()
            )}
          </div>
        )}
      </Row>
    </div>
  );
};

export default Opportunities;
