import React, { useState } from "react";
import "./Navbar.less";
import { Button } from "antd";
import clsx from "clsx";

function Navbar({ tabs, activeTab, setActiveTab }) {
  return (
    <nav>
      <div className="menuBar">
        {tabs.map((tab) => (
          <Button
            type="text"
            className={clsx([
              "tabsBtn",
              activeTab === tab.tabKey && "activeTab",
            ])}
            onClick={() => setActiveTab(tab.tabKey)}
            key={tab.index}
          >
            <img src={tab.tabImage} alt={tab.tabName} />
            {tab.tabName}
            <hr />
          </Button>
        ))}
      </div>
    </nav>
  );
}

export default Navbar;
