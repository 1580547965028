import React from "react";
import { Skeleton } from "antd";
import "./OpportunityCardSkeleton.less";

const OpportunityCardSkeleton = () => {
  return (
    <div className="skeletonDiv">
      <div className="div1">
        <Skeleton.Avatar shape="square" size="large" />
      </div>
      <div className="div2">
        <Skeleton.Input className="input1" active size="small" />
      </div>
      <div className="div3">
        <Skeleton.Button active size="small" shape="square" />
      </div>
      <div>
        <div className="div4">
          <Skeleton.Button active size="small" shape="square" />
        </div>
        <div className="div4">
          <Skeleton.Button active size="small" shape="square" />
        </div>
      </div>
      <div className="div1">
        <Skeleton.Input active className="input2" size="small" />
      </div>
    </div>
  );
};

export default OpportunityCardSkeleton;
