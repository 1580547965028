import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import MakeProfile from "./MakeProfile";
import MentorMakeProfile from "./MentorMakeProfile";
import MentorViewProfile from "./MentorViewProfile";
import ViewProfile from "./ViewProfile";

const Profile = () => {
  const [editProfile, setEditProfile] = useState(false);
  const { mentor } = useContext(AuthContext);
  const [orgList, setOrgList] = useState([]);
  const [interestList, setInterestList] = useState([]);

  const getData = async () => {
    const response = await fetch(
      `${process.env.GATSBY_BE_URL}/getMentorFormData/`
    );
    const data = await response.json();
    setOrgList(data.orgs);
    setInterestList(data.interests);
  };

  useEffect(() => {
    if (mentor) {
      getData();
    }
  }, []);

  return editProfile ? (
    mentor ? (
      <MentorMakeProfile
        interestList={interestList}
        orgList={orgList}
        setEditProfile={setEditProfile}
      />
    ) : (
      <MakeProfile setEditProfile={setEditProfile} />
    )
  ) : mentor ? (
    <MentorViewProfile
      setEditProfile={setEditProfile}
      interestList={interestList}
    />
  ) : (
    <ViewProfile setEditProfile={setEditProfile} />
  );
};

export default Profile;
