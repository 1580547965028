import React, { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { Link } from "gatsby";
import "./SideBar.less";
import { Layout, Menu, Typography } from "antd";
import Guest from "../../../assets/sidebar/guest.svg";
import SignIn from "../../../assets/sidebar/signIn.svg";
import SignOut from "../../../assets/sidebar/signOut.svg";
import MentroLogo from "../../../assets/mentroWebApp/mentroLogo.svg";

const { Sider } = Layout;
const { Text, Title } = Typography;

const SideBar = ({
  sideBarLinks,
  tabs,
  activeTab,
  activeSidebarTab,
  setActiveSidebarTab,
  onClose,
}) => {
  const authContext = useContext(AuthContext);
  const { user, isLoggedIn, logout } = authContext;

  return (
    <Sider className="siderComp">
      <div className="logoDiv">
        <Link to="/">
          <img src={MentroLogo} alt="Logo" />
        </Link>
      </div>
      <div className="profileDiv">
        <img
          src={
            isLoggedIn ? (user.portFolioUrl ? user.portFolioUrl : Guest) : Guest
          }
          alt="Profile"
        />
        <Title className="title">{user ? user.userName : "Guest"}</Title>
      </div>
      <Menu selectedKeys={[String(activeSidebarTab)]}>
        {sideBarLinks.map((link) => {
          if (link.key === 2 && !isLoggedIn) {
            return;
          }
          return (
            <Menu.Item
              key={link.key}
              onClick={() => {
                onClose();
                setActiveSidebarTab(link.key);
                if (link.link) {
                  window.location.href = link.link;
                }
              }}
            >
              {link.icon}
              <Text className="text">{link.name}</Text>
            </Menu.Item>
          );
        })}
        <Menu.Item key="6" onClick={() => isLoggedIn && logout()}>
          {!isLoggedIn ? (
            <Link to="/auth">
              <img src={SignIn} alt="Sign in" />
              <Text className="text">Sign in</Text>
            </Link>
          ) : (
            <>
              <img src={SignOut} alt="Sign out" />
              <Text className="text">Sign out</Text>
            </>
          )}
        </Menu.Item>
      </Menu>
      <div className="filterDiv">
        {activeSidebarTab === 1 &&
          tabs.filter((t) => t.tabKey === activeTab)[0].tabFilter}
      </div>
    </Sider>
  );
};

export default SideBar;
