import React from "react";
import "./Mentors.less";
import { Button, Col, Row, Typography } from "antd";
import MentorsTab from "../../../assets/mentroWebApp/mentorsTab.png";

const { Title, Text } = Typography;

const Mentors = () => {
  return (
    <div className="mentorsTabDiv">
      <Row className="mentorsRow">
        <Col className="contentCol" lg={12}>
          <Title className="contentTitle">
            Want some fresh Tips about your Career?
          </Title>
          <Text className="contentText">
            Download the “Mentro app” from play store to get a fresh advise from
            our mentors for free.
          </Text>
          <Button
            type="text"
            className="visitBtn"
            onClick={() => (window.location.href = "https://mentro.tech/")}
          >
            Visit site
          </Button>
        </Col>
        <Col className="imageCol" lg={12}>
          <img src={MentorsTab} alt="mentors tab" />
        </Col>
      </Row>
    </div>
  );
};

export default Mentors;
