import React, { useContext, useEffect, useState } from "react";
import "./ViewProfile.less";
import { AuthContext } from "../../../../context/AuthContext";
import { Form, Input, Button, Typography, Row, Col, Tag, message } from "antd";
import EditIcon from "../../../../assets/mentroWebApp/editIcon.svg";
import DefaultUser from "../../../../assets/mentroWebApp/defaultUser.svg";
import LinkedinIcon from "../../../../assets/mentroWebApp/linkedin.svg";
import GithubIcon from "../../../../assets/mentroWebApp/github.svg";
import { useProfile } from "../../../../hooks/useProfile";

const { Text, Title } = Typography;

const ViewProfile = ({ setEditProfile }) => {
  const authContext = useContext(AuthContext);
  const { token } = authContext;
  const [isLoading, setIsLoading] = useState(false);
  // const [userData, setUserData] = useState<any>(null);

  // const getUserDetails = async () => {
  //   setIsLoading(true);
  //   try {
  //     const response = await fetch(
  //       `${process.env.GATSBY_BE_URL}/users/getUserProfile`,
  //       {
  //         method: "GET",
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     const userData = await response.json();
  //     console.log({ userData });
  //     setUserData(userData.user);
  //     setIsLoading(false);
  //   } catch (error) {
  //     setIsLoading(false);
  //     console.error(error);
  //   }
  // };

  // useEffect(() => {
  //   getUserDetails();
  // }, []);

  const { userData } = useProfile();

  console.log({ userData });

  return (
    <div className="viewProfileParentDiv">
      <div className="viewProfileDiv">
        <div className="editDiv" onClick={() => setEditProfile(true)}>
          <img src={EditIcon} alt="edit profile icon" />
          <Text className="text">Edit Profile</Text>
        </div>
        <div className="profilePicture">
          <img
            src={userData?.imageUrl ? userData?.imageUrl : DefaultUser}
            alt="user"
          />
          <Text className="name">{userData?.userName}</Text>
          <Text className="org">{userData?.institution}</Text>
        </div>
        <div className="interestsDiv">
          <Title className="title">Interests</Title>
          {userData?.interests?.map((intr) => (
            <span className="interest">{intr} </span>
          ))}
        </div>
        <div className="bioDiv">
          <Title className="title">Bio</Title>
          <Text className="text">{userData?.bio}</Text>
        </div>
        <div className="socialDiv">
          <Title className="title">Social Platforms</Title>
          {userData?.linkedIn && (
            <span onClick={() => window.open(userData?.linkedIn)}>
              <img src={LinkedinIcon} alt="linkedin" />
            </span>
          )}
          {userData?.github && (
            <span onClick={() => window.open(userData?.github)}>
              <img src={GithubIcon} alt="linkedin" />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewProfile;
