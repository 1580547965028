import React, { useState } from "react";
import "./MobileNavbar.less";
import { Button, Drawer } from "antd";
import MentroLogo from "../../../../assets/mentroWebApp/mentroLogo.svg";
import SideBar from "../../SideBar";
import HamburgerMenu from "../../../../assets/mentroWebApp/hamburgerMenu.svg";

const MobileNavbar = ({
  sideBarLinks,
  tabs,
  activeTab,
  activeSidebarTab,
  setActiveSidebarTab,
}) => {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <nav className="mobileMainNavbarParent">
      <div className="mobileMainNavbar">
        <div className="logoDiv">
          <a href="/">
            <img src={MentroLogo} alt="Mentro Logo" />
          </a>
        </div>
        <div>
          <Button type="primary" onClick={showDrawer} className="drawerBtn">
            <img src={HamburgerMenu} alt="menu icon" />
          </Button>
          <Drawer
            placement="right"
            closable={false}
            onClose={onClose}
            visible={visible}
          >
            <SideBar
              sideBarLinks={sideBarLinks}
              onClose={onClose}
              tabs={tabs}
              activeTab={activeTab}
              activeSidebarTab={activeSidebarTab}
              setActiveSidebarTab={setActiveSidebarTab}
            />
          </Drawer>
        </div>
      </div>
    </nav>
  );
};

export default MobileNavbar;
