import React from "react";
import "./WebLayout.less";
import { Layout } from "antd";
import Navbar from "../Navbars/Navbar";
import SideBar from "../SideBar";

const { Content } = Layout;

const WebLayout = ({
  sideBarLinks,
  tabs,
  activeTab,
  setActiveTab,
  activeSidebarTab,
  setActiveSidebarTab,
}) => {
  return (
    <>
      <SideBar
        sideBarLinks={sideBarLinks}
        onClose={() => {}}
        tabs={tabs}
        activeTab={activeTab}
        activeSidebarTab={activeSidebarTab}
        setActiveSidebarTab={setActiveSidebarTab}
      />
      <Layout>
        {activeSidebarTab === 1 && (
          <Navbar
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}
        <Content
          style={{
            minHeight: "90vh",
            background: "#FAFAFB",
            paddingLeft: "200px",
            display: "flex",
            marginTop: activeSidebarTab === 1 ? "10vh" : "0vh",
            justifyContent: "center",
          }}
        >
          {activeSidebarTab === 1
            ? tabs.filter((t) => t.tabKey === activeTab)[0].tabComponent
            : sideBarLinks[activeSidebarTab - 1].component}
        </Content>
      </Layout>
    </>
  );
};

export default WebLayout;
