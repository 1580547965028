import React from "react";
import "./MobileLayout.less";
import { Layout } from "antd";
import MobileNavbar from "../Navbars/MobileNavbar";
import BottomBar from "../BottomBar";

const { Content } = Layout;

const MobileLayout = ({
  sideBarLinks,
  tabs,
  activeTab,
  setActiveTab,
  activeSidebarTab,
  setActiveSidebarTab,
}) => {
  return (
    <>
      <Layout>
        <MobileNavbar
          sideBarLinks={sideBarLinks}
          tabs={tabs}
          activeTab={activeTab}
          activeSidebarTab={activeSidebarTab}
          setActiveSidebarTab={setActiveSidebarTab}
        />
        <Content
          style={{
            minHeight:
              activeSidebarTab === 1
                ? "calc(100vh - 137px)"
                : "calc(100vh - 62px)",
            background: "#FAFAFB",
            display: "flex",
            marginTop: "62px",
            marginBottom: activeSidebarTab === 1 ? "75px" : "0px",
            justifyContent: "center",
          }}
        >
          {activeSidebarTab === 1
            ? tabs.filter((t) => t.tabKey === activeTab)[0].tabComponent
            : sideBarLinks[activeSidebarTab - 1].component}
        </Content>
      </Layout>
      {activeSidebarTab === 1 && (
        <BottomBar
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      )}
    </>
  );
};

export default MobileLayout;
