import React, { useState, useEffect } from "react";

const useJobSearch = () => {
  const [companyType, setCompanyType] = useState<string>("");
  const [roleType, setRoleType] = useState<string>("");
  const [docId, setDocId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [hasMoreJobs, setHasMoreJobs] = useState<boolean>(false);
  const [jobOpportunities, setJobOpportunities] = useState<any>([]);
  const [dataReceived, setDataReceived] = useState<boolean>(false);

  useEffect(() => {
    console.log({ docId });

    if (!hasMoreJobs) {
      return;
    }

    setLoading(true);
    setError(false);
    fetch(
      `${
        process.env.GATSBY_BE_URL
      }/users/get-jobs?companyType=${companyType}&roleType=${roleType}&docId=${docId}&numJobs=${12}`
    )
      .then((res) => res.json())
      .then((responseData: { data: any; message: any }) => {
        if (responseData.message === "Success") {
          setJobOpportunities((prevData) => {
            return [...prevData, ...responseData.data];
          });
          console.table(responseData.data);
          setHasMoreJobs(responseData.data.length > 0);
          setError(false);
        }
      })
      .catch((err) => {
        setError(true);
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [docId]);

  useEffect(() => {
    console.log({ docId });
    setLoading(true);
    setDataReceived(false);
    setError(false);
    fetch(
      `${
        process.env.GATSBY_BE_URL
      }/users/get-jobs?companyType=${companyType}&roleType=${roleType}&docId=${docId}&numJobs=${12}`
    )
      .then((res) => res.json())
      .then((responseData: { data: any; message: any }) => {
        if (responseData.message === "Success") {
          setJobOpportunities(responseData.data);
          setDataReceived(true);
          console.table(responseData.data);
          setHasMoreJobs(responseData.data.length > 0);
          setError(false);
        }
      })
      .catch((err) => {
        setError(true);
        setDataReceived(false);
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [companyType, roleType]);

  return {
    loading,
    jobOpportunities,
    setJobOpportunities,
    hasMoreJobs,
    setCompanyType,
    companyType,
    setRoleType,
    roleType,
    setDocId,
    dataReceived,
  };
};

export default useJobSearch;
