import React from "react";
import Seo from "../components/seo";
import Container from "../components/Container";
import "../theme/index.less";
import { useAuth } from "../hooks/useAuth";
import { AuthContext } from "../context/AuthContext";

const IndexPage = () => {
  const { mentor, mentorData, token, user, login, logout } = useAuth();

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token,
        mentor,
        mentorData,
        user,
        login,
        logout,
      }}
    >
      <Seo title="Mentro: Easy Access to Professional Guidance" />
      <Container />
    </AuthContext.Provider>
  );
};

export default IndexPage;
