import React, { useState } from "react";
import "./BottomBar.less";
import { Button, Typography } from "antd";
import clsx from "clsx";

const { Text } = Typography;

function BottomBar({ tabs, activeTab, setActiveTab }) {
  return (
    <div className="bottomBar">
      {tabs.map((tab) => (
        <Button
          type="text"
          className={clsx(["tabsBtn", activeTab === tab.tabKey && "activeTab"])}
          onClick={() => setActiveTab(tab.tabKey)}
          key={tab.index}
        >
          <img
            src={
              activeTab === tab.tabKey && "activeTab"
                ? tab.tabActiveImage
                : tab.tabImage
            }
            alt={tab.tabName}
          />
          <Text className="tabName">{tab.tabName}</Text>
        </Button>
      ))}
    </div>
  );
}

export default BottomBar;
