import { Button, Typography } from "antd";
import React from "react";
import "./OpportunityCard.less";
import Briefcase from "../../assets/mentroWebApp/briefcase.svg";
import Location from "../../assets/mentroWebApp/location.svg";

const { Text, Title } = Typography;

const OpportunityCard = ({ jobOpp }) => {
  return (
    <div className="opportunityCardDiv">
      <img
        src={jobOpp.logo !== "" ? jobOpp.logo : Briefcase}
        alt="company Logo"
      />
      <div className="headDiv">
        <Title className="role">{jobOpp?.role}</Title>
        <Text className="companyName">{jobOpp?.companyName}</Text>
      </div>
      <div className="bodyDiv">
        <div className="locationTag">
          <img src={Location} alt="Location" />
          <span>
            {jobOpp?.location.length > 30
              ? `${jobOpp?.location.substring(0, 29)}...`
              : jobOpp?.location}
          </span>
        </div>
        <div className="tags">
          <span>{jobOpp?.companyType}</span> <span>{jobOpp?.roleType}</span>
        </div>
      </div>
      <div className="bottomDiv">
        <Button
          type="text"
          className="applyNowBtn"
          onClick={() => window.open(jobOpp?.link)}
        >
          Apply Now
        </Button>
      </div>
    </div>
  );
};

export default OpportunityCard;
