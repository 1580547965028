import React from "react";
import "./MentorViewProfile.less";
import { Typography } from "antd";
import EditIcon from "../../../../assets/mentroWebApp/editIcon.svg";
import DefaultUser from "../../../../assets/mentroWebApp/defaultUser.svg";
import LinkedinIcon from "../../../../assets/mentroWebApp/linkedin.svg";
import GithubIcon from "../../../../assets/mentroWebApp/github.svg";
import { useProfile } from "../../../../hooks/useProfile";

const { Text, Title } = Typography;

const MentorViewProfile = ({ setEditProfile, interestList }) => {
  const { userData } = useProfile();
  const { mentorData } = userData;
  console.log("MENTOR DATA: ", userData);

  return (
    <div className="viewProfileParentDiv">
      <div className="viewProfileDiv">
        <div className="editDiv" onClick={() => setEditProfile(true)}>
          <img src={EditIcon} alt="edit profile icon" />
          <Text className="text">Edit Profile</Text>
        </div>
        <div className="profilePicture">
          <img
            src={mentorData?.profileUrl ? mentorData?.profileUrl : DefaultUser}
            alt="user"
          />
          <Text className="name">{mentorData?.name}</Text>
          <Text className="org">
            {mentorData?.role} at {mentorData?.organisation}
          </Text>
          <Text className="org quote">"{mentorData?.quote}"</Text>
        </div>
        <div className="interestsDiv">
          <Title className="title">Interests</Title>
          {mentorData?.interests?.map((intr) => (
            <span className="interest">
              {interestList.find((intrs) => intrs.value === intr)?.label}{" "}
            </span>
          ))}
        </div>
        <div className="bioDiv">
          <Title className="title">Bio</Title>
          <Text className="text">{mentorData?.bio}</Text>
        </div>
        <div className="socialDiv">
          <Title className="title">Social Platforms</Title>
          {mentorData?.portFolioUrl && (
            <span onClick={() => window.open(mentorData?.portFolioUrl)}>
              <img src={LinkedinIcon} alt="linkedin" />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default MentorViewProfile;
